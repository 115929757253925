h1,
h2,
h3,
h4,
h5,
h6 {
  // I hate that this works, CSS is the language of the devil
  &:not(:hover) > a.anchor-heading {
    color: transparent;
  }
}

hr {
  border-color: rgba(255, 255, 255, 0.2);
  width: 200px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

table {
  margin-bottom: 1rem;
  border: 1px solid hsl(162, 5%, 25%);

  tr {
    border-bottom: 1px solid hsl(162, 5%, 25%);

    td,
    th {
      padding: 0.5rem;
      border-right: 1px solid hsl(162, 5%, 25%);
    }
  }
}

table.adj-list {
  font-family: "Fira Code", monospace;
  margin-left: auto;
  margin-right: auto;
  th {
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: normal;
  }
  tr td:first-child {
    background-color: rgba(255, 255, 255, 0.05);
    text-align: right;
  }
  tr td:not(:first-child) {
    background-color: #0d1117;
  }
}

.byline {
  color: hsla(0, 0%, 100%, 0.5);

  address,
  time {
    display: inline;
  }

  address {
    padding: 0;
    margin: 0;
  }

  .tag {
    color: hsla(0, 0%, 100%, 0.5);

    &:hover {
      color: hsla(0, 0%, 100%, 0.6);
    }
  }
}

:is(p, figure):has(img:not(.emoji)) {
  text-align: center;

  img {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 500px;
    }
  }
}

.emoji {
  height: 1.5rem;
}

.adjacent-pages {
  h1, a {
    margin-bottom: .2rem;
  }

  a {
    color: hsl(162, 100%, 37%);
    font-size: 2rem;
  }
}
