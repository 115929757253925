html,
body {
  font-family: "Nunito", sans-serif;
  background-color: hsl(162, 10%, 7%);
  color: hsl(162, 0%, 85%);
  height: 100%;
  min-height: 100%;
}

p,
li {
  font-size: 1.1rem;
}

.year-group {
  font-size: 1.5rem;
  color: hsl(162, 10%, 60%);
  border-bottom: 2px solid hsl(162, 10%, 20%);
}

span.redacted {
  background-color: hsl(162, 10%, 85%);
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  color: transparent;

  > * {
    visibility: hidden;
  }

  &:hover {
    background-color: transparent;
    color: unset;

    > * {
      visibility: visible;
    }
  }
}

code,
pre,
kbd {
  font-family: "Fira Code", monospace;
  font-size: 0.9rem;
}

div[class^="language-"] pre.highlight {
  border-radius: 0.25rem;
  border: 1px solid hsl(162, 5%, 25%);
}

blockquote {
  border-left: 3px solid hsla(0, 0%, 100%, 0.2);
  padding-left: 1rem;
  font-style: italic;
  color: hsla(0, 0%, 100%, 0.7);
}

a {
  color: hsl(162, 100%, 37%);
  text-decoration: none;

  &:hover {
    color: hsl(162, 100%, 40%);
  }
}

header nav {
  background: black;
}

.navbar-brand > * {
  vertical-align: middle;
}

.navbar-toggler {
  border: none;
}

footer {
  text-align: center;
  background-color: black;
  color: hsl(162, 10%, 85%);
}

code.language-plaintext {
  color: inherit;
  background-color: hsl(162, 10%, 15%);
  padding: 0.1rem 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid hsl(162, 5%, 25%);
}

pre:has(code.language-mermaid) {
  display: flex;
  color: white;
}

code.language-mermaid {
  margin-left: auto;
  margin-right: auto;
}

#logo-bar a {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 576px) {
  #logo-bar {
    width: 100%;
  }
}

@media (min-width: 576px) {
  #logo-bar {
    width: auto;
  }
}
